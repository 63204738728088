import React from 'react';
import badgeIcon from '../assets/icons/badge.svg';
import codeIcon from '../assets/icons/code.png';
import codqQualityIcon from '../assets/icons/quality-badge.svg';
import quarterIcon from '../assets/icons/quarter.png';
import codeBlkIcon from '../assets/iconsBlk/code.png';
import '../styles/profile.css';
import { arrToMap, trimString } from '../utils/Utils';
import AccountInfoDisplay from './AccountInfoDisplay';
import BarChart from './BarChart';



const getValue = (map, key1, key2, defaultVal = 0) => {
  if (map && map[key1] && map[key1][key2]) {
    return map[key1][key2];
  }
  else {
    return defaultVal;
  }
}

function formatLangString(lang, hasBadge = 0) {
  lang = trimString(lang, hasBadge ? 18 : 30);
  return (
    <div>
      {lang.split('').map((char, index, arr) => {
        // Apply bigtext if it's the first character, or space, or if it's after a space, or it's uppercase
        const isBigText = index === 0 || arr[index] === ' ' || arr[index - 1] === ' ' || (char === char.toUpperCase() && char.match(/[A-Z]/));

        return (
          <span
            key={index}
            className={isBigText ? 'skill-item-caption-bigtext' : 'skill-item-caption-smalltext'}
          >
            {char}
          </span>
        );
      })}
    </div>
  );
}

const renderLangsData = (langsData, numYears, isCertificate) => {
  return langsData.map((langItems, index) => {
    const lang = langItems['_key'];
    let throughPut = Math.round(langItems.throughput_percentile);
    let quality = Math.round(langItems.quality_percentile);
    if (throughPut > 25) {
      throughPut = 0;
    }
    if (quality > 25) {
      quality = 0;
    }

    return (
      <div key={lang} className="profile-skill-item">
        <div className='profile-skill-item-container'>
          <div className='skill-item-caption'>
            <div className='skill-item-caption-text'>
              {formatLangString(lang, isCertificate ? throughPut : 0)}
              <div className='skill-item-caption-stats'>
                <div><img src={isCertificate ? codeBlkIcon : codeIcon} alt="Average output" title="Avg. Lines Per Quarter" className='icon' /></div>
                <div className='skill-item-caption-stats-text'><em>{Math.round(langItems.mean).toLocaleString()}</em> lines-per-quarter in <em>{langItems.history}</em> quarters</div>
              </div>
            </div>
            <div className='skill-item-badge-container'>
              {throughPut ?
                <div className='skill-item-caption-badge'>
                  <div className='skill-item-caption-badge-text'>
                    <div className='skill-item-caption-badge-label'>Throughput</div>
                    <div>Top {throughPut}%</div>
                  </div>
                  <div className='skill-item-caption-badge-icon'>
                    <img src={badgeIcon} alt="Ranking" title="Ranking (Top X%)" />
                  </div>
                </div>
                : null
              }
              {quality ?
                <div className='skill-item-caption-badge'>
                  <div className='skill-item-caption-badge-text'>
                    <div className='skill-item-caption-badge-label'>Quality</div>
                    <div>Top {quality}%</div>
                  </div>
                  <div className='skill-item-caption-badge-icon'>
                    <img src={codqQualityIcon} alt="Quality" title="Quality (Top X%)" />
                  </div>
                </div>
                : null
              }
            </div>
          </div>
          <div className='skill-item-linechart'>
            <BarChart dates={langItems.date_vector} data={langItems.vector} numYears={numYears} yLabel={'Lines of Code'} chartLabel={'Quarterly Lines of Code'} />
          </div>
        </div>
      </div>
    );
  });
}


const ProgLangs = ({ langsData, numYears, accountInfo, isCertificate = false }) => {
  if (isCertificate) {
    return renderLangsData(langsData, numYears, isCertificate);
  }
  else {
    return (
      <div className="profile-skills">
        <AccountInfoDisplay accountInfo={accountInfo} />
        {renderLangsData(langsData, numYears)}
      </div>
    );
  }
}

const getSortedKeys = (userData, baselines, orderByVal, priorityList = []) => {
  const userKeyMap = new Map();
  userData.forEach(item => {
    if (item['_key'] && item[orderByVal] !== undefined) {
      userKeyMap.set(item['_key'], item[orderByVal]);
    }
  });

  const baseLineKeyMap = new Map();
  // Populate the keyMap with values from baselines. User data takes precedence
  baselines && baselines.forEach(baseline => {
    baseline.forEach(item => {
      if (!userKeyMap.has(item['_key']) && !baseLineKeyMap.has(item['_key']) && item['_key'] && item[orderByVal] !== undefined) {
        baseLineKeyMap.set(item['_key'], item[orderByVal]);
      }
    });
  });

  // Sort the keys in descending order based on their 'orderByVal' values
  const sortedUserKeys = Array.from(userKeyMap.entries())
    .sort((a, b) => b[1] - a[1])
    .map(([key]) => key);

  const sortedBaseLineKeys = Array.from(baseLineKeyMap.entries())
    .sort((a, b) => b[1] - a[1])
    .map(([key]) => key);
  const sortedKeys = sortedUserKeys.concat(sortedBaseLineKeys);
  if (priorityList.length > 0) {
    const priorityListSet = new Set(priorityList);
    // push keys in priorityList to the front
    const priority = [];
    const rest = [];
    for (const key of sortedKeys) {
      if (priorityListSet.has(key)) {
        priority.push(key);
      } else {
        rest.push(key);
      }
    }
    const finalSortedKeys = priority.concat(rest);
    return finalSortedKeys;
  } else {
    return sortedKeys;
  }
}


const ProgLangsWithBaseline = ({ langsData, numYears, baselines, accountInfo, baselineAccountInfos, priorityList }) => {
  const allKeys = getSortedKeys(langsData, baselines, 'mean', priorityList);
  const userMap = arrToMap(langsData);
  const baselineMaps = baselines ? baselines.map(baseline => arrToMap(baseline)) : [{}];
  const baseLineChartlabels = baselines ? baselineAccountInfos.map(baselineAccountInfo => baselineAccountInfo.first_name) : [];
  return (
    <div className="profile-skills">
      <AccountInfoDisplay accountInfo={accountInfo} />
      {allKeys.map((lang, index) => {
        const baseLineDateList = baselines ? baselineMaps.map(baselineMap => getValue(baselineMap, lang, 'date_vector')) : [];
        const baseLineDataList = baselines ? baselineMaps.map(baselineMap => getValue(baselineMap, lang, 'vector')) : [];
        return (
          <div key={lang} className="profile-skill-item-baseline">
            <div className='profile-skill-item-container-baseline'>
              <div className='skill-item-caption-baseline'>
                {formatLangString(lang)}
                <table className='small_table'>
                  <thead>
                    <tr>
                      <th></th>
                      <th><img src={badgeIcon} title="Ranking" alt="Ranking" className='icon-td' /></th>
                      <th><img src={codqQualityIcon} title="Code Quality" alt="Code Quality" className='icon-td' /></th>
                      <th><img src={codeIcon} title="Avg. Output" alt="Average output" className='icon-td' /></th>
                      <th><img src={quarterIcon} title="Num. Quarters" alt="Experience" className='icon-td' /></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td title={accountInfo.first_name}>
                        {accountInfo.first_name}
                      </td>
                      <td>
                        {getValue(userMap, lang, 'throughput_percentile') ?
                          Math.round(getValue(userMap, lang, 'throughput_percentile')) + '%'
                          : 'NA'
                        }
                      </td>
                      <td>
                        {getValue(userMap, lang, 'quality_percentile') ?
                          Math.round(getValue(userMap, lang, 'quality_percentile')) + '%'
                          : 'NA'
                        }
                      </td>
                      <td>
                        {Math.round(getValue(userMap, lang, 'mean')).toLocaleString()}
                      </td>
                      <td>
                        {getValue(userMap, lang, 'history')}
                      </td>
                    </tr>
                    {baselines && baselineAccountInfos.map((baselineAccountInfo, index) => {
                      const baselineMap = baselineMaps[index];
                      return (
                        <tr key={index}>
                          <td title={baselineAccountInfo.first_name}>
                            {baselineAccountInfo.first_name}
                          </td>
                          <td>
                            {Math.round(getValue(baselineMap, lang, 'throughput_percentile')) ?
                              Math.round(getValue(baselineMap, lang, 'throughput_percentile')) + '%'
                              : 'NA'
                            }
                          </td>
                          <td>
                            {getValue(baselineMap, lang, 'quality_percentile') ?
                              Math.round(getValue(baselineMap, lang, 'quality_percentile')) + '%'
                              : 'NA'
                            }
                          </td>
                          <td>
                            {Math.round(getValue(baselineMap, lang, 'mean')).toLocaleString()}
                          </td>
                          <td>
                            {getValue(baselineMap, lang, 'history')}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className='skill-item-linechart'>
                {baselines ? <BarChart dates={getValue(userMap, lang, 'date_vector')} data={getValue(userMap, lang, 'vector')} numYears={numYears}
                  yLabel={'Lines of Code'} chartLabel={accountInfo.first_name} baseLineChartlabels={baseLineChartlabels}
                  baseLineDataList={baseLineDataList} baseLineDateList={baseLineDateList} />
                  : <BarChart dates={getValue(userMap, lang, 'date_vector')} data={getValue(userMap, lang, 'vector')} numYears={numYears}
                    yLabel={'Lines of Code'} chartLabel={accountInfo.first_name} />}
              </div>
            </div>
          </div>
        );
      }
      )}
    </div>
  );
}

const renderSkillsData = (skillsData, numYears, isCertificate) => {
  return skillsData.map((skill, index) => (
    <div key={index} className="profile-skill-item">
      <div key={index} className='profile-skill-item-container'>
        <div className='skill-item-caption'>
          <div className='skill-item-caption-text'>
            {formatLangString(skill['_key'], isCertificate ? Math.round(skill.level_percentile): 0)}
            <div className='skill-item-caption-stats'>
              <div><img src={isCertificate ? codeBlkIcon : codeIcon} alt="Average output" title="Avg. Lines Per Quarter" className='icon' /></div>
              <div className='skill-item-caption-stats-text'><em>{Math.round(skill.code_lines / skill.num_quarters).toLocaleString()}</em> avg in <em>{skill.num_quarters}</em> quarters</div>
            </div>
          </div>
          {(Math.round(skill.level_percentile) && Math.round(skill.level_percentile) < 30) ?
            <div className='skill-item-caption-badge'>
              <div className='skill-item-caption-badge-text'>Top {Math.round(skill.level_percentile)}%</div>
              <div className='skill-item-caption-badge-icon'>
                <img src={badgeIcon} alt="Ranking" title="Ranking (Top X%)" />
              </div>
            </div>
            : null
          }
        </div>
        <div className='skill-item-linechart'>
          <BarChart dates={Object.keys(skill.quarterly_stats)} data={Object.values(skill.quarterly_stats)} numYears={numYears} yLabel={'Skill Contrib. (conf. x # lines)'} chartLabel={'Quarterly Skill Contributions'} />
        </div>
      </div>
    </div>
  ));
}


const DomainSkills = ({ skillsData, numYears, accountInfo, isCertificate = false }) => {
  if (isCertificate) {
    return renderSkillsData(skillsData, numYears, isCertificate);
  } else {
    return (
      <div className="profile-skills">
        <AccountInfoDisplay accountInfo={accountInfo} />
        {renderSkillsData(skillsData, numYears)}
      </div>
    );
  }
}

const DomainSkillsWithBaseline = ({ skillsData, numYears, baselines, accountInfo, baselineAccountInfos, priorityList }) => {
  const allKeys = getSortedKeys(skillsData, baselines, 'code_lines', priorityList);
  const skillsMap = arrToMap(skillsData);
  const baselineMaps = baselines ? baselines.map(baseline => arrToMap(baseline)) : [{}];
  const baseLineChartlabels = baselines ? baselineAccountInfos.map(baselineAccountInfo => baselineAccountInfo.first_name) : [];
  return (
    <div className="profile-skills">
      <AccountInfoDisplay accountInfo={accountInfo} />
      {allKeys.map((skillName, index) => {
        const baseLineDateList = baselines ? baselineMaps.map(baselineMap => Object.keys(getValue(baselineMap, skillName, 'quarterly_stats', {}))) : [];
        const baseLineDataList = baselines ? baselineMaps.map(baselineMap => Object.values(getValue(baselineMap, skillName, 'quarterly_stats', {}))) : [];
        return (
          <div key={index} className="profile-skill-item-baseline">
            <div key={index} className='profile-skill-item-container-baseline'>
              <div className='skill-item-caption-baseline'>
                {formatLangString(skillName)}
                <table className='small_table'>
                  <thead>
                    <tr>
                      <th key='user'></th>
                      <th key='rank'><img src={badgeIcon} title='Ranking' alt="Ranking" className='icon-td' /></th>
                      <th key='exp'><img src={quarterIcon} title="Experience" alt="Experience" className='icon-td' /></th>
                      <th key='avg'><img src={codeIcon} title="Average output" alt="Average output" className='icon-td' /></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {accountInfo.first_name}
                      </td>
                      <td>
                        {Math.round(getValue(skillsMap, skillName, 'level_percentile')) ?
                          Math.round(getValue(skillsMap, skillName, 'level_percentile')) + '%'
                          : 'NA'
                        }
                      </td>
                      <td>
                        {getValue(skillsMap, skillName, 'num_quarters')}
                      </td>
                      <td>
                        {Math.round(getValue(skillsMap, skillName, 'code_lines') / getValue(skillsMap, skillName, 'num_quarters', 1)).toLocaleString()}
                      </td>
                    </tr>
                    {baselines && baselineAccountInfos.map((baselineAccountInfo, index) => {
                      const baselineMap = baselineMaps[index];
                      return (
                        <tr key={index}>
                          <td>
                            {baselineAccountInfo.first_name}
                          </td>
                          <td>
                            {Math.round(getValue(baselineMap, skillName, 'level_percentile')) ?
                              Math.round(getValue(baselineMap, skillName, 'level_percentile')) + '%'
                              : 'NA'
                            }
                          </td>
                          <td>
                            {getValue(baselineMap, skillName, 'num_quarters')}
                          </td>
                          <td>
                            {Math.round(getValue(baselineMap, skillName, 'code_lines') / (getValue(baselineMap, skillName, 'num_quarters') + 1)).toLocaleString()}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className='skill-item-linechart' key={index}>
                {baselines ?
                  <BarChart dates={Object.keys(getValue(skillsMap, skillName, 'quarterly_stats', {}))} data={Object.values(getValue(skillsMap, skillName, 'quarterly_stats', {}))}
                    numYears={numYears} yLabel={'Skill Contrib. (conf. x # lines)'} chartLabel={accountInfo.first_name} baseLineChartlabels={baseLineChartlabels}
                    baseLineDataList={baseLineDataList} baseLineDateList={baseLineDateList} />
                  : <BarChart dates={Object.keys(getValue(skillsMap, skillName, 'quarterly_stats', {}))} data={Object.values(getValue(skillsMap, skillName, 'quarterly_stats', {}))}
                    numYears={numYears} yLabel={'Skill Contrib. (conf. x # lines)'} chartLabel={accountInfo.first_name} />
                }
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}


export { DomainSkills, DomainSkillsWithBaseline, ProgLangs, ProgLangsWithBaseline };


