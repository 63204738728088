import React from 'react';
import '../styles/root.css';
import '../styles/footer.css';

const Footer = () => {
    return (
        <footer className='footer'>
            <div className='footer-content'>
                <div> <p>&copy; 2025 <a href='https://modelteam.ai' target='_blank' rel="noreferrer">ModelTeam.ai</a> &trade;.  All rights reserved worldwide. </p></div>
                <a href='https://www.modelteam.ai/#terms' target='_blank' rel="noreferrer">Terms</a>
                <a href='https://www.modelteam.ai/#privacy' target='_blank' rel="noreferrer">Privacy</a>
                {/* <a href='/security' target='_blank' rel="noreferrer">Security</a> */}
                <a href='https://modelteam.ai/#faq' target='_blank' rel="noreferrer"><b>FAQs</b></a>
                <a href="mailto:contact@modelteam.ai">Contact</a>
            </div>
        </footer >
    );
};

export default Footer;