import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import modelteam_icon33 from '../assets/modelteam_icon33.svg';
import logo from '../assets/modelteam_logo.png';
import '../styles/header.css';
import '../styles/root.css';
import { isOrgTokenExpired, isTokenExpired } from '../utils/CheckToken';

const Header = () => {

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();
    const currPage = window.location.pathname;
    const [iconDest, setIconDest] = useState('https://modelteam.ai/');
    const isLoginPage = currPage.includes('/login');
    const isOrg = currPage.includes('/org') || currPage.includes('/org/');
    useEffect(() => {
        var loggedIn = false;
        if (isOrg) {
            loggedIn = !isOrgTokenExpired(false);
        } else {
            loggedIn = !isTokenExpired(false);
        }
        if (loggedIn) {
            setIconDest(isOrg ? '/org/' : '/');
        }
        setIsLoggedIn(loggedIn);
    }, [navigate]);

    return (
        <header className="header">
            <Link to={iconDest} target='_blank' rel="noreferrer">
                <img src={modelteam_icon33} alt="ModelTeam.ai" className='modelteam-icon' />
                <img src={logo} alt="ModelTeam.ai" className='small-logo' />
            </Link>
            {isOrg && <div><label>enterprise</label></div>}
            {
                !isLoginPage ? <div className="header-navi">
                    {isLoggedIn ?
                        <div className="header-link">
                            {isOrg ? <label></label> : <Link to="/refer">Refer a Friend</Link>}
                            <Link to={(isOrg ? '/org' : '') + "/logout"}>Logout</Link>
                        </div>
                        : <div className='header-link'><Link to={(isOrg ? '/org' : '') + "/login"}>Signup / Login</Link></div>
                    }
                </div> : null
            }
        </header>
    );
};

export default Header;