// src/Jobs.js
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import editIcon from '../assets/icons/edit.svg';
import MultiSelect from '../components/MultiSelect';
import MutexLists from '../components/MutexLists';
import OrgLayout from '../components/OrgLayout';
import ProfileTab from '../components/ProfileTab';
import Search from '../components/Search';
import Tab from '../components/Tab';
import '../styles/copilot.css';
import '../styles/jobs.css';
import '../styles/profile.css';
import '../styles/root.css';
import { fetchAPI, fetchAPIWithToken } from '../utils/ApiUtils';
import { isOrgTokenExpired, ORG_PREFIX } from '../utils/CheckToken';

const teamPrefix = '/api/v1/org/team';
const searchAPIPrefix = '/api/v1/org/search';
const jobPrefix = '/api/v1/org/jobs/candidates';
const candidateApi = '/api/v1/org/jobs/candidate';


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const OrgHiringCoPilot = () => {
    const ADD_NEW = '!addNew!';
    const CHRONOS = '!chronos!';
    const query = useQuery();
    const navigate = useNavigate();
    const jobHash = query.get('job_hash') || '';
    const jobName = query.get('job_name') || '';
    const teamHash = query.get('team_hash') || '';
    const teamName = query.get('team_name') || '';
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoginChecked, setIsLoginChecked] = useState(false);
    const [isCopilot, setIsCopilot] = useState(true);
    const [hasTeamProfiles, setHasTeamProfiles] = useState(false);
    const [teamMembers, setTeamMembers] = useState([]);
    const [cohorts, setCohorts] = useState([]);
    const [savedSearches, setSavedSearches] = useState([]);
    const [isCurrUserCohort, setIsCurrUserCohort] = useState(false);
    const [currSelectedUserHash, setCurrSelectedUserHash] = useState('');
    const [currSelectedProfile, setCurrSelectedProfile] = useState(null);
    const [orgAccountUserEmailHash, setOrgAccountUserEmailHash] = useState(localStorage.getItem(ORG_PREFIX + 'email_hash'));
    const [currUserLangs, setCurrUserLangs] = useState([]);
    const [currUserSkills, setCurrUserSkills] = useState([]);
    const [selectedLangs, setSelectedLangs] = useState([]);
    const [selectedSkills, setSelectedSkills] = useState([]);
    const profilesCache = {};
    const [allLangs, setAllLangs] = useState([]);
    const [allSkills, setAllSkills] = useState([]);
    const [jobCandidates, setJobCandidates] = useState([]);
    const [candidatesJobHash, setCandidatesJobHash] = useState('');
    const [baselines, setBaseLines] = useState([]);
    const [baselineAccountInfos, setBaselineAccountInfos] = useState([]);
    const [currRecipe, setCurrRecipe] = useState({
        recipe_hash: '',
        job_hash: jobHash,
        recipe_name: '',
        recipe: { 'langs': [], 'skills': [], 'isCopilot': true, 'isCohort': false, 'userHash': '' },
        creted_by: orgAccountUserEmailHash,
        updated_by: orgAccountUserEmailHash,
        is_public: false
    });
    const [currRecipeHash, setCurrRecipeHash] = useState('');
    const [reloadRecipes, setReloadRecipes] = useState(false);
    const [editRecipe, setEditRecipe] = useState(false);
    const [currSelUserIndex, setCurrSelUserIndex] = useState('');
    const [currSelCohortIndex, setCurrSelCohortIndex] = useState('');
    const [resultsReady, setResultsReady] = useState(false);
    const [fullScreen, setFullScreen] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [filterChoices, setFilterChoices] = useState([]);
    const [choices, setChoices] = useState(['👍', '❓', '👎']);
    const [pageNum, setPageNum] = useState(1);
    const [filteredCandidates, setFilteredCandidates] = useState([]);
    const [currPageCandidates, setCurrPageCandidates] = useState([]);
    const [recipeSkillsAndLangs, setRecipeSkillsAndLangs] = useState([]);

    const loadCandidates = async () => {
        if (!jobHash || candidatesJobHash === jobHash) {
            return;
        }
        if (!jobCandidates || jobCandidates.length === 0) {
            const response = await fetchAPIWithToken(navigate, `${jobPrefix}?job_hash=${jobHash}`, 'GET', {});
            if (response.status !== 200) {
                console.error(response);
                return;
            }
            setResultsReady(false);
            setCandidatesJobHash(jobHash);
            const data = await response.json();
            setJobCandidates(data);
            setFilteredCandidates(data);
        }
    }

    useEffect(() => {
        if (filteredCandidates.length === 0) {
            setCurrPageCandidates([]);
            return;
        }
        const start = (pageNum - 1) * 10;
        const end = Math.min(start + 10, filteredCandidates.length);
        setCurrPageCandidates(filteredCandidates.slice(start, end));
    }, [filteredCandidates, pageNum]);

    useEffect(() => {
        if (!jobHash || !teamHash) {
            navigate('/org/jobs');
        }
        setIsLoggedIn(!isOrgTokenExpired());
        setIsLoginChecked(true);
        if (!allLangs || allLangs.length === 0 || !allSkills || allSkills.length === 0) {
            fetchAPI(searchAPIPrefix + '/config', 'GET').then((response) => {
                if (response.status !== 200) {
                    console.error(response);
                    return;
                }
                response.json().then((data) => {
                    setAllLangs(data.langs);
                    setAllSkills(data.skills);
                });
            });
        }
    }, [allLangs, allSkills, jobHash, teamHash, navigate]);

    useEffect(() => {
        if (reloadRecipes) {
            getRecipes();
            setReloadRecipes(false);
            // this is called after editing a recipe
            setResultsReady(false);
            rerank(false);
        }
    }, [reloadRecipes]);

    useEffect(() => {
        if (currRecipeHash) {
            setEditRecipe(false);
            const cr = savedSearches.find(x => x.recipe_hash === currRecipeHash);
            if (!cr) {
                return;
            }
            setCurrRecipe(cr);
            setIsCopilot(cr.recipe.isCopilot);
            if (cr.recipe.isCopilot) {
                setIsCurrUserCohort(cr.recipe.isCohort);
                if (cr.recipe.isCohort && cohorts) {
                    const ch = cohorts.find(x => x.user_hash === cr.recipe.userHash);
                    if (ch) {
                        setCurrSelCohortIndex(ch.user_email);
                        setCurrSelectedUserHash(ch.user_hash);
                        setCurrSelUserIndex('');
                    }
                } else if (teamMembers) {
                    const u = teamMembers.find(x => x.user_hash === cr.recipe.userHash);
                    if (u) {
                        setCurrSelUserIndex(u.user_email);
                        setCurrSelectedUserHash(u.user_hash);
                        setCurrSelCohortIndex('');
                    }
                }
            }
        } else {
            setCurrRecipe({
                recipe_hash: '',
                job_hash: jobHash,
                recipe_name: '',
                recipe: { 'langs': [], 'skills': [], 'isCopilot': true, 'isCohort': false, 'userHash': '' },
                creted_by: orgAccountUserEmailHash,
                updated_by: orgAccountUserEmailHash,
                is_public: false
            });

            setSelectedLangs([]);
            setSelectedSkills([]);
            setIsCopilot(true);
            setIsCurrUserCohort(false);
            setCurrSelectedUserHash('');
            setCurrSelectedProfile(null);
            setCurrSelUserIndex('');
            setCurrSelCohortIndex('');
        }
    }, [currRecipeHash, savedSearches, teamMembers, cohorts]);

    useEffect(() => {
        if (isLoggedIn) {
            loadTeamMembers(teamHash);
            getRecipes();
        }
    }, [teamHash, isLoggedIn]);

    useEffect(() => {
        if (jobCandidates.length === 0) {
            return;
        }
        if (currRecipeHash === CHRONOS) {
            rerank(true);
        } else if (currRecipe.recipe_hash === currRecipeHash && ((!currRecipe.recipe.isCohort) || (currRecipe.recipe.isCopilot && currSelectedProfile && currRecipe.recipe.userHash === currSelectedUserHash))) {
            rerank(false);
        }
    }, [currRecipeHash, currRecipe, currSelectedProfile, currSelectedUserHash, jobCandidates]);

    useEffect(() => {
        if (isLoggedIn && teamHash && currSelectedUserHash) {
            loadUserProfile();
        } else {
            setCurrSelectedProfile(null);
        }
    }, [teamHash, currSelectedUserHash, isLoggedIn]);


    useEffect(() => {
        const langs = [];
        const skills = [];
        if (currSelectedProfile) {
            currSelectedProfile.display_profile.langs.forEach((item, index) => {
                langs.push({ '_key': item['_key'], 'k_id': item['k_id'], 'top': true });
            });
            currSelectedProfile.display_profile.skills.forEach((item, index) => {
                skills.push({ '_key': item['_key'], 'k_id': item['k_id'], 'top': true });
            });
            // if currprofile is not the one in recipe, then add top skills and langs to selectedSkills and selectedLangs
        }
        // add other langs and skills present in allLangs and allSkills at the end
        allLangs.forEach((item, index) => {
            if (!langs.find(x => x._key === item._key)) {
                langs.push(item);
            }
        });
        allSkills.forEach((item, index) => {
            if (!skills.find(x => x._key === item._key)) {
                skills.push(item);
            }
        });
        setCurrUserLangs(langs);
        setCurrUserSkills(skills);
    }, [currSelectedProfile, allLangs, allSkills, currRecipe]);

    useEffect(() => {
        if (currUserLangs.length > 0 && currUserSkills.length > 0) {
            if (currRecipe.recipe.userHash !== currSelectedUserHash) {
                const topLangs = currUserLangs.filter(x => x.top);
                const topSkills = currUserSkills.filter(x => x.top);
                setSelectedLangs(topLangs.length > 5 ? [...topLangs.slice(0, 5)] : [...topLangs]);
                setSelectedSkills(topSkills.length > 10 ? [...topSkills.slice(0, 10)] : [...topSkills]);
            } else {
                setSelectedLangs(currRecipe.recipe.langs);
                setSelectedSkills(currRecipe.recipe.skills);
            }
        }
    }, [currUserLangs, currUserSkills, currRecipe, currSelectedUserHash]);


    useEffect(() => {
        if (!isCopilot) {
            setCurrSelectedUserHash('');
            setCurrSelectedProfile(null);
            setIsCurrUserCohort(false);
        }
    }, [isCopilot]);

    useEffect(() => {
        loadCandidates();
    }, [jobHash]);

    if (!isLoginChecked) {
        return <div>Loading...</div>;
    }

    if (!isLoggedIn) {
        return <div>Not logged in</div>;
    }

    const loadUserProfile = async () => {
        const userKey = teamHash + currSelectedUserHash;
        if (!profilesCache[userKey]) {
            const response = await fetchAPIWithToken(navigate, `${teamPrefix}/member?team_hash=${teamHash}&user_hash=${currSelectedUserHash}&search=true`, 'GET', {});
            if (response.status !== 200) {
                console.error(response);
                return;
            }
            const data = await response.json();
            profilesCache[userKey] = data;
        }
        setCurrSelectedProfile(profilesCache[userKey]);
    }

    const getRecipes = async () => {
        const response = await fetchAPIWithToken(navigate, `${searchAPIPrefix}/recipes?job_hash=${jobHash}`, 'GET', {});
        if (response.status !== 200) {
            console.error(response);
            return;
        }
        const data = await response.json();
        if (data.length > 0) {
            setSavedSearches(data);
            if (!currRecipeHash) {
                setCurrRecipeHash(data[0].recipe_hash);
            }
        } else {
            setEditRecipe(true);
        }
    }

    const loadTeamMembers = async (teamHash) => {
        if (!teamHash) {
            return;
        }
        // Get team members and cohorts with status >= 2
        const response = await fetchAPIWithToken(navigate, `${teamPrefix}/members?team_hash=${teamHash}&minstatus=2`, 'GET', {});
        if (response.status !== 200) {
            console.error(response);
            return;
        }
        const data = await response.json();
        const members = data.users
        const cohorts = data.cohorts
        setHasTeamProfiles(members.length + cohorts.length > 0);
        if (members.length > 0) {
            setTeamMembers(members);
        } else {
            setTeamMembers([]);
        }
        if (cohorts.length > 0) {
            setCohorts(cohorts);
        } else {
            setCohorts([]);
        }
    };

    const setUserAsCurrProfile = (index) => {
        if (index < 0 || index >= teamMembers.length) {
            return;
        }
        setIsCurrUserCohort(false);
        setCurrSelectedUserHash(teamMembers[index].user_hash);
    }

    const setCohortAsCurrProfile = (index) => {
        if (index < 0 || index >= cohorts.length) {
            return;
        }
        setIsCurrUserCohort(true);
        setCurrSelectedUserHash(cohorts[index].user_hash);
    }

    const saveRecipe = async () => {
        if (!currRecipe.recipe_name || currRecipe.recipe_name === '' || (selectedLangs.length + selectedSkills.length) === 0) {
            alert('Please fill in all fields');
            return;
        }
        const newRecipe = {
            'isCopilot': isCopilot,
            'langs': selectedLangs,
            'skills': selectedSkills,
            'isCohort': isCurrUserCohort,
            'userHash': isCopilot ? currSelectedUserHash : ''
        }
        currRecipe.recipe = newRecipe;
        let response = null;
        if (currRecipe.recipe_hash === '') {
            response = await fetchAPIWithToken(navigate, `${searchAPIPrefix}/recipes`, 'POST', currRecipe);
        } else {
            response = await fetchAPIWithToken(navigate, `${searchAPIPrefix}/recipes`, 'PUT', currRecipe);
        }
        if (response.status !== 200 && response.status !== 201) {
            console.error(response);
            return;
        }
        const responseJson = await response.json();
        setCurrRecipeHash(responseJson.recipe_hash);
        setReloadRecipes(true);
    }

    function generateSearchSummary(sbProfile) {
        const searchSummary = {};

        sbProfile.skills.forEach((skill) => {
            const quarterlyStats = skill.quarterly_stats;
            const yxs = {};

            Object.entries(quarterlyStats).forEach(([q, sl]) => {
                const year = q.slice(0, 4);
                yxs[year] = (yxs[year] || 0) + parseInt(sl, 10);
            });
            searchSummary[skill['k_id']] = yxs;
        });
        sbProfile.langs.forEach((lang) => {
            const yxl = {};

            lang.date_vector.forEach((q, index) => {
                const year = q.slice(0, 4);
                const sl = lang.vector[index];
                yxl[year] = (yxl[year] || 0) + parseInt(sl, 10);
            });
            searchSummary[lang['k_id']] = yxl;
        });
        return searchSummary;
    }

    // skills can be langs or skills
    function calcScore(query_yx, candidate_yx, recipe_index) {
        if (!query_yx) {
            return 0;
        }
        let qsum = 1;
        for (const year in query_yx) {
            qsum += query_yx[year];
        }
        // qsum = qsum / (Object.keys(query_yx).length - 0.5);
        let csum = 1;
        let numYears = 0;
        for (const year in candidate_yx) {
            if (candidate_yx[year] < 100000 && candidate_yx[year] > 0) {
                csum += candidate_yx[year];
                numYears += 1;
            }
        }
        // csum = csum / (numYears - 0.5);
        const q = Math.min(2, csum / qsum);
        if (recipe_index < 0) {
            return q / 100;
        } else {
            return q / (recipe_index + 1);
        }
    }
    const rerank = async (chronos = false) => {
        if (!jobCandidates || jobCandidates.length === 0 || resultsReady) {
            return;
        }
        if (chronos) {
            jobCandidates.forEach((candidate) => {
                candidate.score = 0;
            });
            const sortedCandidates = jobCandidates.map((x) => x);
            sortedCandidates.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            setJobCandidates(sortedCandidates);
            setFilteredCandidates(sortedCandidates);
            setResultsReady(true);
            setBaseLines([]);
            setBaselineAccountInfos([]);
        } else if (currRecipe.recipe.isCopilot && currSelectedProfile && currSelectedProfile.display_profile) {
            const query_summary = generateSearchSummary(currSelectedProfile.display_profile);
            calculateCandidateScores(query_summary);
        } else if (!currRecipe.recipe.isCopilot) {
            const query_summary = {};
            currRecipe.recipe.langs.forEach((x, idx) => { query_summary[x.k_id] = { '2222': 10000 } });
            currRecipe.recipe.skills.forEach((x, idx) => { query_summary[x.k_id] = { '2222': 10000 } });
            setBaseLines([]);
            setBaselineAccountInfos([]);
            calculateCandidateScores(query_summary);
        }

        function calculateCandidateScores(query_summary) {
            const langsMap = new Map(currRecipe.recipe.langs.map((x, idx) => [x.k_id, idx]));
            const skillsMap = new Map(currRecipe.recipe.skills.map((x, idx) => [x.k_id, idx]));

            const newCandidates = jobCandidates.map((candidate) => {
                const langScore = candidate.search_summary.langs.reduce((acc, lang, index) => {
                    const qsl = query_summary[lang];
                    const csl = candidate.search_summary.years_x_langs[index];
                    return acc + calcScore(qsl, csl, langsMap.get(lang) ?? -1); // Use default index -1 for safety
                }, 0);

                const skillScore = candidate.search_summary.skills.reduce((acc, skill, index) => {
                    const qsl = query_summary[skill];
                    const csl = candidate.search_summary.years_x_skills[index];
                    return acc + calcScore(qsl, csl, skillsMap.get(skill) ?? -1); // Use default index -1 for safety
                }, 0);

                return { ...candidate, score: langScore + skillScore }; // Maintain immutability
            });

            // Sort candidates based on score in descending order
            newCandidates.sort((a, b) => b.score - a.score);
            if (currSelectedProfile && currRecipe.recipe.isCopilot) {
                setBaseLines([currSelectedProfile.display_profile]);
                setBaselineAccountInfos([currSelectedProfile.account_info]);
            }
            setJobCandidates(newCandidates);
            setFilteredCandidates(newCandidates);
            setRecipeSkillsAndLangs(currRecipe.recipe.langs.map(x => x._key).concat(currRecipe.recipe.skills.map(x => x._key)));
            setResultsReady(true);
        }
    }

    const handleLangSelect = (selectedIndices) => {
        setSelectedLangs(selectedIndices.map(index => currUserLangs[index]));
    }

    const handleSkillSelect = (selectedIndices) => {
        setSelectedSkills(selectedIndices.map(index => currUserSkills[index]));
    }

    const displayCoPilotBuilder = () => {
        if (!editRecipe) {
            return null;
        }
        if (isCopilot && !hasTeamProfiles) {
            return <div className='copilot-builder'><h2>You need to create a team profile to use Co-Pilot</h2></div>;
        }

        return (
            <div className='copilot-builder'>
                <div className='copilot-header'>
                    <Tab name="Copilot" isActive={isCopilot} onClick={() => setIsCopilot(true)} />
                    <Tab name="Manual" isActive={!isCopilot} onClick={() => setIsCopilot(false)} />
                </div>
                <div className='whoami'>
                    {isCopilot ? <p>
                        Use Co-Pilot to identify candidates whose skills closely match those of your team. <br />
                        - Similar to User: Find candidates who have similar skills to my star performer. <br />
                        - Similar to Team/Cohort: Find candidates who have similar skills to my team or cohort. <br />
                        Edit Top Langs and Top Skills to refine your search further.
                    </p> : <p>
                        Use Manual to rank candidates based on your own criteria. Select key skills and languages to prioritize candidates. <br />
                        This approach helps you find someone who enhances your team’s capabilities or brings new expertise to broaden your team’s skillset.
                    </p>}
                    {currRecipe.recipe_hash === '' &&
                        <>
                            <label>Saved Search Name</label>
                            <input type='text' value={currRecipe.recipe_name} onChange={(e) => setCurrRecipe({ ...currRecipe, recipe_name: e.target.value })} />
                        </>
                    }
                    {isCopilot && <MutexLists firstList={teamMembers.map(x => x.user_email)} secondList={cohorts.map(x => x.user_email)} firstTitle={'Similar to User'} secondTitle={'Similar to Team/Cohort'} updateFirst={setUserAsCurrProfile} updateSecond={setCohortAsCurrProfile} firstVal={currSelUserIndex} secondVal={currSelCohortIndex} />}
                    <label>Top Langs(Max 5)</label>
                    <MultiSelect items={currUserLangs} maxSel={5} onSelectChange={handleLangSelect} defItems={selectedLangs} itemKey={'_key'} />
                    <label>Top Skills(Max 10)</label>
                    <MultiSelect items={currUserSkills} maxSel={10} onSelectChange={handleSkillSelect} defItems={selectedSkills} itemKey={'_key'} />
                </div>
                <div className='two_buttons'>
                    <button onClick={saveRecipe}>Save</button>
                    <button onClick={() => setEditRecipe(false)}>Cancel</button>
                </div>
            </div>
        )
    }

    const handleRecipeChange = (e) => {
        setResultsReady(false);
        setFilterChoices([]);
        setFilterText('');
        if (e.target.value === ADD_NEW) {
            setCurrRecipeHash('');
            setEditRecipe(true);
        } else {
            setEditRecipe(false);
            setCurrRecipeHash(e.target.value);
        }
    }

    const handleChronosClick = () => {
        setResultsReady(false);
        setFilterChoices([]);
        setFilterText('');
        setEditRecipe(false);
        setCurrRecipeHash(CHRONOS);
    }

    const handleAddNewClick = () => {
        setResultsReady(false);
        setFilterChoices([]);
        setFilterText('');
        setEditRecipe(true);
        setCurrRecipeHash('');
    }

    function richName(search) {
        const prefix = search.recipe.isCopilot ? 'Copilot: ' : 'Manual: ';
        const top3Langs = (search.recipe.langs.length > 3 ? search.recipe.langs.slice(0, 3) : search.recipe.langs).map(x => x['_key']).join(', ');
        const top3Skills = (search.recipe.skills.length > 3 ? search.recipe.skills.slice(0, 3) : search.recipe.skills).map(x => x['_key']).join(', ');
        let name = `${prefix}  ${search.recipe_name} - `;
        if (top3Langs.length > 0) {
            name += `Langs: ${top3Langs}`;
            if (search.recipe.langs.length > 3) {
                name += '...';
            }
        }
        if (top3Skills.length > 0) {
            name += ` Skills: ${top3Skills}`;
            if (search.recipe.skills.length > 3) {
                name += '...';
            }
        }
        return name;
    }

    const handleFilterChoices = (choiceIndices) => {
        setFilterChoices(choiceIndices.map(index => choices[index]));
    }
    const handleInputChange = (event) => {
        setFilterText(event.target.value); // Update state with input value
    };

    const filterRow = (row) => {
        if (filterText === '' && filterChoices.length === 0) {
            return true;
        }
        const filterTextLower = filterText.toLowerCase();
        const choice1 = row.first_name.toLowerCase().includes(filterTextLower) || row.last_name.toLowerCase().includes(filterTextLower) || row.personal_email.toLowerCase().includes(filterText
            .toLowerCase());
        const choice2 = filterChoices.length === 0 || (filterChoices.includes('👍') && row.candidate_status === 1) || (filterChoices.includes('❓') && row.candidate_status === 0) || (filterChoices.includes('👎') && row.candidate_status === -1);
        return choice1 && choice2;
    }

    const handleStatusChange = async (index, status) => {
        const response = await fetchAPIWithToken(navigate, candidateApi, 'PUT', { job_hash: jobHash, c_hash: jobCandidates[index].candidate_email_hash, rating: status });
        if (response.status !== 201) {
            alert('Failed to update status');
            console.error(response);
            return;
        }
        const newFilteredCandidates = filteredCandidates.map((row, i) => {
            if (i === index) {
                return { ...row, candidate_status: status };
            }
            return row;
        });
        setFilteredCandidates(newFilteredCandidates);
        const newJobCandidates = jobCandidates.map((row, i) => {
            if (i === index) {
                return { ...row, candidate_status: status };
            }
            return row;
        });
        setJobCandidates(newJobCandidates);
    }

    const filterCandidates = () => {
        const filtered = jobCandidates.filter((row, index) => {
            if (filterRow(row)) {
                return row;
            } else {
                return null;
            }
        });
        if (filtered.length === 0) {
            alert('No candidates found');
            setFilteredCandidates([]);
        }
        setFilteredCandidates(filtered);
        setPageNum(1);
    }

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            filterCandidates();
        }
    }

    const search = () => {
        return (<div>
            {!fullScreen && <div className="job-candidate-filter">
                <div className='whoami'>
                    <label>Filter By Name/Email</label>
                    <input type="text" placeholder="Search by name/email" value={filterText} onChange={handleInputChange} onKeyDown={handleKeyDown} />
                </div>
                <div className='whoami'>
                    <label>Status</label>
                    <MultiSelect items={choices} maxSel={2} onSelectChange={handleFilterChoices} />
                </div>
                <button onClick={filterCandidates} className='bottom_buttons'>Apply Filter</button>
                <div className='four_small_buttons'>
                    <button onClick={() => setPageNum(1)} className='bottom_buttons'>{'|<<'}</button>
                    <button onClick={() => setPageNum(pageNum - 1)} className='bottom_buttons' disabled={pageNum === 1}>{'<'}</button>
                    <button onClick={() => setPageNum(pageNum + 1)} className='bottom_buttons' disabled={pageNum === Math.ceil(filteredCandidates.length / 10)}>{'>'}</button>
                    <button onClick={() => setPageNum(Math.ceil(filteredCandidates.length / 10))} className='bottom_buttons'>{'>>|'}</button>
                </div>
                <label>Page {pageNum}</label>
            </div>}
            <Search jobCandidates={currPageCandidates} baselines={baselines} baselineAccountInfos={baselineAccountInfos} jobHash={jobHash} toggleFullScreen={() => setFullScreen(!fullScreen)} handleStatusChange={handleStatusChange} recipeSkillsAndLangs={recipeSkillsAndLangs}></Search>
        </div>);
    }

    return (
        <OrgLayout isLoggedIn={isLoggedIn}>
            <h2 className='no_margin_h2'>{teamName + ' | ' + jobName + ' | ' + jobCandidates.length + ' Candidates  '}
                <button onClick={handleChronosClick} className='small_button'>Show All</button>
            </h2>
            {!fullScreen && <div className='copilot'>
                <label>Saved Search Queries (Max 5 Per Job) {currRecipeHash && currRecipeHash != ADD_NEW && savedSearches.length < 5 && <button onClick={handleAddNewClick} className='small_button'>Add New</button>}
                </label>
                <div className='saved_search_names'>
                    <select value={currRecipeHash} onChange={handleRecipeChange}>
                        {savedSearches.length < 5 && <option value={ADD_NEW} key={'addNew'} >Add New</option>}
                        <option value={CHRONOS} key={'chronos'} >Show All (Latest first)</option>
                        {savedSearches.map((search) => (
                            <option key={search.recipe_hash} value={search.recipe_hash}>{richName(search)}</option>
                        ))}
                    </select>
                    {currRecipeHash && currRecipeHash != CHRONOS && <img src={editIcon} alt="edit_profile" className='small_button' title='Edit Query' onClick={() => setEditRecipe(true)} />}
                </div>
                {displayCoPilotBuilder()}
            </div>}
            {isCopilot && editRecipe && currSelectedProfile && <ProfileTab profileData={currSelectedProfile.display_profile} accountInfo={currSelectedProfile.account_info} />}
            {!editRecipe && currRecipeHash && resultsReady && search()}
        </OrgLayout >);
};

export default OrgHiringCoPilot;
